import React from 'react';

import { useNavigate } from 'react-router-dom';
import guidance from '../../assets/example.webp';


function Features() {

    const navigate = useNavigate();



  return (

<div className=''>
<div className='flex flex-col lg:flex-row items-center justify-center bg-gradient-to-r from-white to-[#F9FAFB]'>

<div className="min-h-[350px] flex flex-row items-center justify-center space-y-8 px-0 md:px-8 space-x-8 bg-white/50 min-w-[0px] md:min-w-[500px]">
    <div className='flex flex-col space-y-8 p-8 py-16 max-w-[800px]'>
    <div className='flex flex-col space-y-4 items-start'>
    <span className="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent tracking-tight text-left font-bold text-lg"> Features </span> 
<p className='text-3xl md:text-5xl font-bold text-left tracking-tight'>
Swift global regulatory guidance summaries </p>
    </div>

<p className='text-gray-500 text-lg text-left'>RegChat™ quickly delivers summarized and referenced responses from global regulatory bodies with comparisons to clarify differences in guidance.</p>

<div className='flex flex-col gap-y-8 md:gap-y-16'>
<div className='flex flex-row gap-x-8'>
    <div className='flex flex-col gap-y-2 text-left w-full'>
    <h1 className='text-[#191C85] text-xl md:text-2xl  font-semibold'>
    Multi-Agency Queries
    </h1>
    <p className='text-gray-500 '>
    Get answers from international agencies in seconds.
    </p>
    </div>
    
    <div className='flex flex-col gap-y-2 text-left w-full'>
    <h1 className='text-[#191C85] text-xl md:text-2xl text-nowrap font-semibold'>
    Source Links
    </h1>
    <p className='text-gray-500 '>
    Dive deeper with direct links to source documents.
    </p>
    </div>

</div>
<div className='flex flex-row gap-x-8'>
<div className='flex flex-col gap-y-2 text-left w-full'>
    <h1 className='text-[#191C85] text-xl md:text-2xl  text-nowrap font-semibold'>
    Comparisons    </h1>
    <p className='text-gray-500 '>
    Summarized comparisons of selected regulatory sources.    </p>
    </div>
    
    <div className='flex flex-col gap-y-2 text-left w-full'>
    <h1 className='text-[#191C85] text-xl md:text-2xl  text-nowrap font-semibold'>
    Latest Intelligence    </h1>
    <p className='text-gray-500 '>
    Stay informed with the latest guidance updates.     </p>
    </div>

</div>

</div>
 
    </div>

    </div>
    <img src={guidance} className=' min-h-100% w-full max-w-[600px]  '></img>


    </div>

</div>
  );
}

export default Features;
