// KnowledgeResearchAgent.js
import React from 'react';
import customicon from "../../assets/icons/Icon6.png"
import customicon2 from "../../assets/icons/Icon7.svg"
import knowledgeAgent from "../../assets/knowledge-agent.webp"


function KnowledgeResearchAgent() {
  return (
<div id="knowledge-research" className='w-screen flex flex-col items-center justify-start border-b-2 p-4 md:p-16 space-y-4 md:space-y-8 bg-[#F1F1F8]/50'>
<div className='max-w-screen-xl mx-auto w-full'>
<div className='w-full flex flex-col space-y-2 items-start px-4 md:px-20 md:py-0 py-4 pb-0 '>
        <span className="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent tracking-tight text-sm md:text-xl font-bold text-left">Intelligent Information Retrieval</span>
        <p className='text-2xl md:text-4xl font-bold text-gray-900 dark:text-white tracking-tight text-left md:text-nowrap'>Knowledge & Research Agents</p>
    </div>

    <div className='flex flex-col md:flex-row items-center justify-center w-full gap-4 md:gap-y-4 md:gap-x-8 px-4 md:pr-8 py-4 md:py-8'>
        <div className='bg-white border rounded-xl md:rounded-3xl p-4 md:p-14 w-full md:w-1/3 md:max-w-[500px] min-w-[350px] flex flex-col items-center'>
            <div className='flex flex-col space-y-12 items-center'>
                <div className='flex flex-col space-y-2 items-start text-left'>
                    <img src={customicon} alt="Icon" className="mb-3 w-8 h-8 md:w-10 md:h-10" />
                    <p className='text-lg md:text-2xl tracking-tight font-bold text-[#191C85] text-left'>Knowledge Agent</p>
                    <p className='text-sm md:text-base text-left text-gray-500'>Quickly answer questions using the knowledge found in your documents with rapid answers and reference links to the source documents. Access insights from your SOPs, RFI/RFP libraries, or InfoSec documentation instantly.</p>
                </div>

                <div className='flex flex-col space-y-2 items-start text-left'>
                    <img src={customicon2} alt="Icon" className="mb-3 w-8 h-8 md:w-10 md:h-10" />
                    <p className='text-lg md:text-2xl tracking-tight font-bold text-[#191C85] text-left'>Research Agent</p>
                    <p className='text-sm md:text-base text-left text-gray-500'>Perform complex queries across documents and databases using a natural language interface. Extract insights from protocols, clinical study reports, or company experience with intuitive conversational queries.</p>
                </div>
            </div>
        </div>

        <div className='w-full md:w-2/3 flex justify-center items-center rounded-xl md:rounded-3xl mt-8 md:mt-0 md:min-w-[500px] max-w-full'>
            <img src={knowledgeAgent} alt="Knowledge and Research" className="max-w-[80%] md:max-w-[70%] h-auto rounded-xl shadow-lg"></img>
        </div>
    </div>
</div>
   
</div>


  );
}

export default KnowledgeResearchAgent;