// DocumentAgent.js
import React from 'react';
import customicon from "../../assets/icons/Icon6.png"
import customicon2 from "../../assets/icons/Icon7.svg"
import documentready from "../../assets/documentready.svg"
import icfcopilot from "../../assets/ICFcopilot.webp"


function DocumentAgent() {
  return (
<div id="document-agents" className='w-screen flex flex-col items-center justify-start border-b-2 p-4 md:p-16 space-y-4 md:space-y-8'>
<div className='max-w-screen-xl mx-auto w-full'>
<div className='w-full flex flex-col space-y-2 items-start px-4 md:px-20 md:py-0 py-4 pb-0'>
  <span className="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent tracking-tight text-sm md:text-xl font-bold text-left">Automated Document Creation</span>
  <p className='text-2xl md:text-4xl font-bold text-gray-900 dark:text-white tracking-tight text-left md:text-nowrap'>Document Generation Agent</p>
</div>

    <div className='flex flex-col md:flex-row items-center justify-center w-full gap-4 md:gap-y-4 md:gap-x-8 px-4 md:pr-8 py-4 md:py-8'>
    <div className='w-full md:w-2/3 flex justify-center items-center rounded-xl md:rounded-3xl mt-8 md:mt-0 md:min-w-[500px] max-w-full'>
            <img src={icfcopilot} alt="Document Generation" className="max-w-full h-auto rounded-xl shadow-lg"></img>
        </div>
        <div className='bg-white p-4 md:p-10 w-full md:w-1/2 md:max-w-[500px] min-w-[350px] flex flex-col items-center'>
            <div className='flex flex-col space-y-6 items-start'>
                <div className='flex flex-col space-y-2 items-start text-left'>
                    <p className='text-sm md:text-base text-left text-gray-500 m-1'>Dynamically generate accurate, consistent standardized output documents by leveraging your templates, libraries, and SOPs. Create with unprecedented efficiency:</p>
                    
                    <ul className="text-left space-y-1 text-sm md:text-base text-gray-700 mt-2 ml-4">
                        <li className="flex items-start">
                            <span className="text-[#191C85] mr-2">•</span>
                            <span>Informed Consent Forms (ICFs)</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-[#191C85] mr-2">•</span>
                            <span>Study Project Plans</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-[#191C85] mr-2">•</span>
                            <span>Clinical Study Reports (CSRs)</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-[#191C85] mr-2">•</span>
                            <span>Protocols and Protocol Amendments</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-[#191C85] mr-2">•</span>
                            <span>RFP/RFI Responses</span>
                        </li>
                    </ul>
                </div>               
            </div>
        </div>  
    </div>
</div>   
</div>


  );
}

export default DocumentAgent;