import React from 'react';
import Footer from "../Footer"

import background from '../../assets/gradientbackground.webp';
import { useNavigate } from 'react-router-dom';


function CTA() {

    const navigate = useNavigate();

    const goToContact = () => {
      navigate('/#contact'); 
    };


  return (
    <div id = "customenterprise" style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}>
<div className="min-h-[350px] flex flex-col items-start justify-center space-y-8 p-12 md:p-24">
    <div className='flex flex-col space-y-4 items-start'>
    <span className="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent tracking-tight font-bold text-lg"> Enterprise Solutions </span> 
<p className='text-2xl md:text-5xl font-bold tracking-tight text-left'>
Custom Enterprise GPTs    </p>
    </div>

<p className='text-gray-500 text-lg text-left'>
Connect your enterprise documents with Kenosha AI to enable conversational AI chat against them to provide rapid and well-informed answers to your questions using all of the content in those documents, such as contracts, SOPs, internal intelligence libraries, etc.    </p>

<button className='bg-black hover:bg-black/80 text-white p-3 px-6 rounded-xl font-semibold'       onClick={goToContact} >Get in touch</button>

    </div>


    </div>
  );
}

export default CTA;
