import React from 'react';
import sopAnimation from '../../assets/sop-animation.svg';

const scrollToSection = (sectionId) => {
  const section = document.querySelector(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};



function Landing() {
  return (
    <section
    className="py-12 flex flex-col gap-y-4 md:items-center items-start justify-center"
    style={{
      backgroundImage: "url('/background.webp')",
      backgroundSize: 'cover',
      backgroundPosition: 'top',
      backgroundColor: '#f8fafc' // Fallback background color
    }}
  >
    
  <div className='flex flex-col gap-y-2 w-full max-w-4xl px-4 '>
    <h1 className="mb-4 text-4xl md:text-6xl font-extrabold tracking-tight leading-none text-left md:text-center dark:text-white">
      Operational AI for the <span className="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent">Clinical Research</span> Industry
    </h1>
    <h2 className='text-lg md:text-xl text-left md:text-center text-slate-600'>
    Kenosha AI is transforming clinical research operations with enterprise-grade AI to accelerate clinical trial timelines, enhance decision making ability, and ultimately speed access to life-saving treatments.    </h2>
  </div>
  
    <div className='flex flex-col items-center w-full'>
      {/* Header text above the animation */}
      <div className="text-center mt-6 mb-4 px-4 max-w-4xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-[#191C85] mb-0">
          Kenosha AI Eliminates E6 R3 Transition Pain
        </h2>
        <p className="text-xl md:text-2xl text-gray-600">
          Seamless GCP Regulatory Compliance
        </p>
      </div>
      
      {/* SVG Animation */}
      <div className='pt-0 pb-0 flex justify-center w-full max-w-4xl mx-auto px-4'>
        <object 
          type="image/svg+xml"
          data={sopAnimation + '?v=' + new Date().getTime() + '&r=' + Math.random()} 
          className="rounded-2xl w-full"
          aria-label="SOP automation process"
          style={{ height: "auto", minHeight: "380px" }}
        >
          <img className="rounded-2xl w-full" src={sopAnimation} alt="SOP automation process" />
        </object>
      </div>
      
      {/* Bullet points below animation */}
      <div className="text-center px-4 -mt-12 mb-5">
        <h3 className="text-2xl md:text-3xl font-bold text-[#191C85] mb-4">
          Automated SOP Updates
        </h3>
        <ul className="flex flex-col items-center max-w-2xl mx-auto space-y-1 text-lg md:text-xl text-gray-700">
          <li className="flex w-full max-w-md">
            <span className="text-[#191C85] mr-2">•</span>
            <span>Analyzes SOPs and controlled documents</span>
          </li>
          <li className="flex w-full max-w-md">
            <span className="text-[#191C85] mr-2">•</span>
            <span>Identifies required updates</span>
          </li>
          <li className="flex w-full max-w-md">
            <span className="text-[#191C85] mr-2">•</span>
            <span>Provides compliant revision language</span>
          </li>
        </ul>
      </div>
      
      {/* Let's Talk button */}
      <div className="flex flex-col items-center w-full mt-2 mb-8">
        <button
          onClick={() => scrollToSection('#contact')}
          className="bg-[#191C85] hover:bg-[#191C85]/80 text-white hover:text-gray-100 transition ease-in duration-150 rounded-xl py-4 px-12 text-lg font-semibold"
        >
          Let's Talk
        </button>
        
        <p className="mt-2 text-center text-md md:text-lg text-[#191C85] font-medium max-w-xl px-4 py-1 border border-dashed border-[#191C85]/50 rounded-lg bg-[#191C85]/5">
          Contact us for a special new customer promotion in advance of E6 implementation deadlines
        </p>
      </div>
    </div>
  </section>
  
  );
}

export default Landing;
