// RegulatorComplianceAgent.js
import React from 'react';
import regchaticon from "../../assets/icons/Icon4.svg"
import compliance from "../../assets/compliance.webp"

function RegulatoryComplianceAgent() {
  return (
    <div id="regulatory-compliance" className='w-screen flex flex-col items-center justify-start border-b-2 p-4 md:p-16 space-y-4 md:space-y-8 bg-[#F1F1F8]/50'>
      <div className='max-w-screen-xl mx-auto w-full'>
        <div className='w-full flex flex-col space-y-2 items-start px-4 md:px-20 md:py-0 py-4 pb-0'>
          <span className="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent tracking-tight text-sm md:text-xl font-bold text-left">GCP E6 R3 Compliance</span>
          <p className='text-2xl md:text-4xl font-bold text-gray-900 dark:text-white tracking-tight text-left md:text-nowrap'>Regulatory Compliance Agent</p>
        </div>

        <div className='flex flex-col md:flex-row items-center justify-center w-full gap-4 md:gap-y-4 md:gap-x-8 px-4 md:pr-8 py-4 md:py-8'>
          <div className='bg-white border rounded-xl md:rounded-3xl p-4 md:p-14 w-full md:w-1/3 md:max-w-[500px] min-w-[350px] flex flex-col items-center'>
            <div className='flex flex-col space-y-6 items-start'>
              <p className='text-sm md:text-base text-left text-gray-500'>Review all of your SOPs and related controlled documents and suggest updates or additions to bring them in compliance with the latest GCP guidelines.</p>
              
              <ul className="text-left space-y-2 text-sm md:text-base text-gray-700 mt-2">
                <li className="flex items-start">
                  <span className="text-[#191C85] mr-2">•</span>
                  <span>Comprehensive analysis of all controlled documents</span>
                </li>
                <li className="flex items-start">
                  <span className="text-[#191C85] mr-2">•</span>
                  <span>Identification of gaps in current documentation</span>
                </li>
                <li className="flex items-start">
                  <span className="text-[#191C85] mr-2">•</span>
                  <span>Generation of revision language to meet E6 R3 standards</span>
                </li>
                <li className="flex items-start">
                  <span className="text-[#191C85] mr-2">•</span>
                  <span>Clear guidance on prioritization for implementation</span>
                </li>
                <li className="flex items-start">
                  <span className="text-[#191C85] mr-2">•</span>
                  <span>Change management and implementation support</span>
                </li>
              </ul>
            </div>
          </div>

          <div className='w-full md:w-2/3 flex justify-center items-center rounded-xl md:rounded-3xl mt-8 md:mt-0 md:min-w-[500px] max-w-full'>
            <img src={compliance} alt="Regulatory Compliance" className="max-w-full h-auto rounded-xl shadow-lg"></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegulatoryComplianceAgent;